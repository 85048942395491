import React from "react";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import "./CounterItem.scss";

const CounterItem = ({ counterData }) => {
  return (
    <div className="counter-item">
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <div style={{ height: 100 }}>
            {isVisible && (
              <CountUp
                end={counterData.metricValue}
                className="counter-value"
                duration={3}
                separator=","
                suffix={counterData.metricSuffix || ""}
                prefix={counterData.metricPrefix || ""}
              />
            )}
          </div>
        )}
      </VisibilitySensor>

      {counterData.metricName && (
        <p className="counter-name">{counterData.metricName}</p>
      )}
      {counterData.metricDescription && (
        <div
          className="counter-description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: counterData.metricDescription,
          }}
        />
      )}
      {counterData.footnotes && (
        <p className="counter-footnotes">{counterData.footnotes}</p>
      )}
    </div>
  );
};

CounterItem.propTypes = {
  counterData: PropTypes.shape({
    metricValue: PropTypes.number,
    metricName: PropTypes.string,
    metricDescription: PropTypes.string,
    metricPrefix: PropTypes.string,
    metricSuffix: PropTypes.string,
    footnotes: PropTypes.string,
  }).isRequired,
};

export default CounterItem;
