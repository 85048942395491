import React from "react";
import PropTypes from "prop-types";
import uniqueId from "lodash/uniqueId";
import { Link } from "gatsby";
import IsrLabel from "../IsrLabel/IsrLabel";
import Jumbotron from "../Jumbotron/Jumbotron";
import Spacer from "../Spacer/Spacer";
import CounterItem from "./components/CounterItem/CounterItem";
import IsrButton from "../IsrButton/IsrButton";
import IsrLink from "../IsrLink/IsrLink";
import PromoSlotsSection from "../PromoSlotsSection/PromoSlotsSection";
import ProjectCard from "../ProjectCard/ProjectCard";
import "./Crisis.scss";

const Crisis = ({ crisisData }) => {
  const getExcerptFromHtml = (pageData) => {
    if (pageData?.pageExcerpt && pageData?.pageExcerpt?.pageExcerpt)
      return pageData.pageExcerpt.pageExcerpt;
    if (pageData?.content) {
      let text = pageData.content.replace(/<[^>]+>/g, "").toString();
      if (text.length > 180) text = `${text.substring(0, 180)}...`;
      return text;
    }
    return false;
  };

  const getCrisisStatusLabel = () => {
    switch (crisisData.crisis.crisisStatus) {
      case "Preparatory":
        return (
          <IsrLabel
            variant="hoverable"
            color="blue"
            size="md"
            hoverMessage={crisisData?.crisis?.crisisStatusHoverText}
          >
            Preparatory
          </IsrLabel>
        );
      case "Declared":
        return (
          <IsrLabel
            variant="hoverable"
            color="pink"
            size="md"
            hoverMessage={crisisData?.crisis?.crisisStatusHoverText}
          >
            Declared
          </IsrLabel>
        );
      case "Proposal submissions now closed":
        return (
          <IsrLabel
            variant="hoverable"
            color="grey"
            size="md"
            hoverMessage={crisisData?.crisis?.crisisStatusHoverText}
          >
            Proposal submissions now closed
          </IsrLabel>
        );

      default:
        return null;
    }
  };

  return (
    <div className="crisis-single">
      <Jumbotron
        media={crisisData.crisis.headingSection.backgroundImage}
        title={crisisData.title}
        dynamicPanelMessage={crisisData.crisis.headingSection.dynamicPanel}
      />
      <div className="container">
        <Spacer mobileSize={50} size={100} />
        <div className="crisis-status">{getCrisisStatusLabel()}</div>
        {crisisData.crisis.crisisDeclarationDate && (
          <p className="sub-info">
            Declaration date:<b> {crisisData.crisis.crisisDeclarationDate}</b>
          </p>
        )}
        {crisisData.crisis.crisisIncidentPeriod && (
          <p className="sub-info">
            Incident period:<b> {crisisData.crisis.crisisIncidentPeriod}</b>
          </p>
        )}
        {crisisData.crisis?.crisisOverviewHeading && (
          <section className="membersSection">
            <div className="container">
              <Spacer size={60} mobileSize={30} />
              <h2>{crisisData.crisis?.crisisOverviewHeading}</h2>
              <Spacer size={40} mobileSize={20} />
              <hr size="3" className="divider grey" />
              <Spacer size={40} mobileSize={20} />
            </div>
          </section>
        )}

        {crisisData.crisis?.crisisOverview && (
          <div className="bottomPart">
            <Spacer mobileSize={30} size={30} />
            <div className="row">
              <div className="col-md-2" />
              <div
                className="col-md-8 content-wrapper"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: crisisData.crisis?.crisisOverview,
                }}
              />
              <div className="col-md-2" />
            </div>
            <Spacer mobileSize={25} size={75} />
          </div>
        )}

        <div className="counters">
          {crisisData.crisis.metricsSection &&
            crisisData.crisis.metricsSection.map((metric) => (
              <CounterItem counterData={metric} key={uniqueId("metric_")} />
            ))}
        </div>
        <Spacer mobileSize={25} size={75} />

        {crisisData.crisis?.crisisLessonsHeading && (
          <section className="membersSection">
            <div className="container">
              <h2>{crisisData.crisis?.crisisLessonsHeading}</h2>
              <Spacer size={40} mobileSize={20} />
              <hr size="3" className="divider grey" />
              <Spacer size={40} mobileSize={20} />
            </div>
          </section>
        )}

        {crisisData?.crisis?.crisisLessons && (
          <div className="bottomPart">
            <Spacer mobileSize={30} size={30} />
            <div className="row">
              <div className="col-md-2" />
              <div
                className="col-md-8 content-wrapper"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: crisisData?.crisis?.crisisLessons,
                }}
              />
              <div className="col-md-2" />
            </div>
            <Spacer mobileSize={25} size={75} />
          </div>
        )}

        {crisisData.crisis.whatWeLearnedSection &&
          crisisData.crisis.whatWeLearnedSection[0] && (
            <div className="what-we-learned-section">
              <hr size="3" />
              <Spacer mobileSize={50} size={100} />
              <h2>What We Learned</h2>
              <Spacer size={20} mobileSize={10} />
              <div className="row">
                <div className="col-md-2" />
                <div
                  className="col-md-8 counter-description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: getExcerptFromHtml(
                      crisisData.crisis.whatWeLearnedSection[0]
                    ),
                  }}
                />

                <div className="col-md-2" />
                {crisisData.crisis.whatWeLearnedSection[0].uri && (
                  <>
                    <Spacer mobileSize={30} size={30} />
                    <IsrButton type="button" variant="primary" size="md">
                      <IsrLink
                        link={{
                          url: crisisData.crisis.whatWeLearnedSection[0].uri,
                        }}
                      >
                        Read More
                      </IsrLink>
                    </IsrButton>
                  </>
                )}
                <Spacer mobileSize={50} size={100} />
              </div>
            </div>
          )}
      </div>
      {crisisData.asignPromoSlots && (
        <PromoSlotsSection data={crisisData.asignPromoSlots} />
      )}
      <div className="container">
        <div className="featured-projects">
          {(crisisData.crisis.featuredProjectsSection.heading ||
            crisisData.crisis.featuredProjectsSection.copy) && (
            <>
              <Spacer mobileSize={50} size={100} />
              {crisisData.crisis.featuredProjectsSection.heading && (
                <>
                  <h2>{crisisData.crisis.featuredProjectsSection?.heading}</h2>
                  <Spacer mobileSize={10} size={10} />
                </>
              )}

              {crisisData.crisis.featuredProjectsSection.copy && (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: crisisData.crisis.featuredProjectsSection.copy,
                  }}
                />
              )}
              <Spacer mobileSize={10} size={20} />
              <IsrButton type="button" variant="primary" size="md">
                <Link to="/projects">See all</Link>
              </IsrButton>
              <Spacer mobileSize={30} size={50} />
            </>
          )}

          <div className="featured-projects-container">
            {crisisData.crisis.featuredProjectsSection.featuredProjects &&
              crisisData.crisis.featuredProjectsSection.featuredProjects.map(
                (project) => (
                  <ProjectCard
                    ProjectData={project.selectProject}
                    key={uniqueId("featured_proj_")}
                  />
                )
              )}
          </div>
        </div>
        <Spacer mobileSize={50} size={100} />
      </div>
    </div>
  );
};

Crisis.propTypes = {
  crisisData: PropTypes.shape({
    title: PropTypes.string,
    asignPromoSlots: PropTypes.object,
    crisis: PropTypes.shape({
      headingSection: PropTypes.shape({
        dynamicPanel: PropTypes.string,
        backgroundImage: PropTypes.object,
      }),
      crisisStatusHoverText: PropTypes.string,
      crisisDeclarationDate: PropTypes.string,
      crisisIncidentPeriod: PropTypes.string,
      crisisOverview: PropTypes.string,
      crisisStatus: PropTypes.string,
      metricsSection: PropTypes.array,
      whatWeLearnedSection: PropTypes.array,
      featuredProjectsSection: PropTypes.shape({
        heading: PropTypes.string,
        copy: PropTypes.string,
        featuredProjects: PropTypes.array,
      }),
    }),
  }).isRequired,
};

export default Crisis;
