import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Crisis from "../components/Crisis/Crisis";

const CrisisPostTemplate = ({ data: { crisis } }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <Crisis crisisData={crisis} />
        <Footer />
      </Layout>
    </>
  );
};

export default CrisisPostTemplate;

export const pageQuery = graphql`
  query CrisisPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current news by id
    crisis: wpCrisis(id: { eq: $id }) {
      id
      uri
      link
      title
      crisis {
        crisisOverview
        crisisStatus
        crisisStatusHoverText
        crisisDeclarationDate
        crisisIncidentPeriod
        crisisLessons
        crisisLessonsHeading
        crisisOverviewHeading
        headingSection {
          dynamicPanel
          heading
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: WEBP)
              }
            }
            altText
          }
        }
        metricsSection {
          metricName
          metricDescription
          metricValue
          metricSuffix
          metricPrefix
          footnotes
        }
        whatWeLearnedSection {
          ... on WpCrisisLearned {
            id
            content
            pageExcerpt {
              pageExcerpt
            }
            uri
          }
        }
        featuredProjectsSection {
          copy
          heading
          featuredProjects {
            selectProject {
              ... on WpISRProject {
                title
                uri
                date(formatString: "MMMM DD, YYYY")
                author {
                  node {
                    firstName
                    lastName
                    name
                  }
                }
                projectSingle {
                  country
                  crisisName {
                    ... on WpCrisis {
                      id
                      uri
                      title
                    }
                  }
                  projectStatus
                }
                projectsCategories {
                  nodes {
                    name
                    uri
                  }
                }
              }
            }
          }
        }
      }
      asignPromoSlots {
        heading
        copy
        choosePromoSlots {
          ... on WpPromoSlot {
            id
            promoSlotsFields {
              promoSlotsGroup {
                chooseBlogPost {
                  ... on WpPost {
                    id
                    excerpt
                    content
                    title
                    date(formatString: "MMMM DD, YYYY")
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]

                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                    uri
                    categories {
                      nodes {
                        name
                        uri
                        id
                      }
                    }
                    author {
                      node {
                        firstName
                        lastName
                        nicename
                        nickname
                        uri
                      }
                    }
                  }
                }
                choosePage {
                  ... on WpPage {
                    id
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                  }
                }
                promoSlotType
                content {
                  copy
                  link {
                    target
                    title
                    url
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }

      seo {
        canonical
        cornerstone
        focuskw
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterTitle
        schema {
          raw
        }
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        opengraphImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
    }
  }
`;
