import React from "react";
import { Link } from "gatsby";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import Spacer from "../Spacer/Spacer";
import IsrLabel from "../IsrLabel/IsrLabel";
import IsrButton from "../IsrButton/IsrButton";
import "./ProjectCard.scss";

const ProjectCard = ({ ProjectData }) => {
  const {
    title,
    uri,
    date,
    author: {
      node: { firstName, lastName },
    },
    projectSingle: { country, crisisName, projectStatus },
    projectsCategories: { nodes: categories },
  } = ProjectData;

  return (
    <div className="projectCard">
      <Link to={uri}>{title && <p>{title}</p>}</Link>
      <Spacer mobileSize={10} size={10} />
      <hr size="3" />
      <Spacer mobileSize={10} size={10} />
      {firstName && lastName && (
        <p className="authorName">{`${firstName} ${lastName}`}</p>
      )}
      {/* <p>University of Alabama, Birmingham</p> */}
      {date && <p>{date}</p>}
      <Spacer mobileSize={165} size={115} />
      <div className="bottomPart">
        <div className="categories">
          {crisisName && (
            <Link to={crisisName.uri}>
              <IsrLabel variant="secondary" color="grey" size="md">
                {crisisName.title}
              </IsrLabel>
            </Link>
          )}

          {projectStatus && (
            <IsrLabel variant="secondary" color="grey" size="md">
              {projectStatus}
            </IsrLabel>
          )}
          {categories &&
            categories.map((category) => (
              <Link
                to={category.uri}
                title={category.name}
                key={uniqueId("bp_cat_")}
              >
                <IsrLabel variant="secondary" size="md" color="grey">
                  {category.name}
                </IsrLabel>
              </Link>
            ))}
          {country && (
            <IsrLabel variant="secondary" size="md" color="grey">
              {country[1]}
            </IsrLabel>
          )}
        </div>
        <Link to={uri}>
          <IsrButton variant="arrow" type="button" size="md" />
        </Link>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  ProjectData: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    date: PropTypes.string,
    author: PropTypes.shape({
      node: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    projectSingle: PropTypes.shape({
      country: PropTypes.array,
      crisisName: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      projectStatus: PropTypes.string,
    }),
    projectsCategories: PropTypes.shape({ nodes: PropTypes.array }),
  }).isRequired,
};
export default ProjectCard;
